import 'www/doctors.mobile.blocks/common/init-lazyload/init-lazyload'
import ExpansionPanels from 'modules/ExpansionPanels'

const expansionPanelElement = document.getElementById('footer-expansion-panels')

if (expansionPanelElement) {
  new ExpansionPanels({
    target: expansionPanelElement,
  })
}
