const reviewContainer = document.querySelector('.p-404__review')

if (!reviewContainer) {
  throw new Error('reviewContainer not found', 'blocks/library.mobile.blocks/common/p-404/p-404.js')
}

const reviews = [
  '«Не заходите сюда. Только зря время и нервы потратите»',
  '«Я пришёл сюда, чтобы что?! Узнать, что здесь ничего нет?!»',
  '«Никому не рекомендую эту страницу»',
  '«Понравилась картинка. Не понравилось всё остальное»',
]

let nextReviewId = 1

function setNextReview() {
  reviewContainer.textContent = reviews[nextReviewId]
  nextReviewId++

  if (nextReviewId === 4) {
    nextReviewId = 0
  }
}

reviewContainer.addEventListener('animationend', setNextReview)
reviewContainer.addEventListener('animationiteration', setNextReview)
