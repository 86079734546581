var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "VBottomSheet",
    {
      staticClass: "suggest-medtochka-bottom-sheet",
      attrs: { value: _vm.value, transition: "fade-transition" },
      on: {
        input: function ($event) {
          return _vm.$emit("input", $event)
        },
      },
    },
    [
      _c(
        "VSheet",
        { staticClass: "px-4 pt-4 pb-6", attrs: { color: "bg-gray-0" } },
        [
          _c(
            "div",
            {
              staticClass:
                "suggest-medtochka-bottom-sheet__header d-flex justify-end",
            },
            [
              _c(
                "VCard",
                {
                  staticClass: "suggest-medtochka-bottom-sheet__icon",
                  attrs: {
                    width: "72",
                    height: "72",
                    elevation: "1",
                    rounded: "lg",
                  },
                },
                [
                  _c("VImg", {
                    attrs: {
                      src: "/static/_v1/pd/icons/ui-kit/medtochka/icon.svg",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "VIcon",
                {
                  attrs: { color: "icon-secondary", size: "24" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("input", false)
                    },
                  },
                },
                [_vm._v("\n        ui-icon-close-not-a-circle\n      ")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "ui-text ui-text_h6 mt-4" }, [
            _vm._v(
              "\n      Приложение МедТочка — как ПроДокторов, но ещё лучше!\n    "
            ),
          ]),
          _vm._v(" "),
          _vm._l(_setup.FEATURE_LIST, function (feature) {
            return _c(
              "div",
              {
                key: feature.icon,
                staticClass: "d-flex align-center ui-text ui-text_body-2 mt-4",
              },
              [
                _c(
                  "VIcon",
                  {
                    staticClass: "mr-4",
                    attrs: { color: "icon-secondary", size: "24" },
                  },
                  [_vm._v("\n        " + _vm._s(feature.icon) + "\n      ")]
                ),
                _vm._v("\n      " + _vm._s(feature.text) + "\n    "),
              ],
              1
            )
          }),
          _vm._v(" "),
          _c(
            "VBtn",
            {
              staticClass: "mt-4",
              attrs: { color: "primary", depressed: "", block: "" },
              on: { click: _setup.handleClickDownloadApp },
            },
            [_vm._v("\n      Скачать приложение\n    ")]
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }