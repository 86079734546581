/* eslint-disable */
(function () {
    $(document).on("click", ".p-page__show-full-text-button", function (e) {
        const button = $(this);
        const buttonId = button.attr("data-id");
        const descriptionNode = $(`.p-page__intro-description[data-id = ${buttonId}]`);
        let containerHeight;

        if (descriptionNode.attr("data-height")) {
            containerHeight = descriptionNode.attr("data-height");
        } else {
            containerHeight = descriptionNode.height();
            descriptionNode.attr("data-height", containerHeight);
        }

        if (descriptionNode.attr("data-state") === "close") {
            descriptionNode.css("height", containerHeight + "px");
            descriptionNode.find(".b-gradient-block").show();
            descriptionNode.attr("data-state", "open");

            if (button.data('text')) {
                button.text(button.data('text'))
            } else {
                button.text("Раскрыть всё");
            }

        } else {
            descriptionNode.css("height", "auto");
            descriptionNode.find(".b-gradient-block").hide();
            descriptionNode.attr("data-state", "close");
            button.text("Свернуть");
        }
    })
}());
