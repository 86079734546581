import Vue from 'components'
import vuetify from 'modules/vuetify-plugin'
import ShortcutHomeScreen from 'components/common/ShortcutHomeScreen/components/ShortcutHomeScreen.vue'
import { MOUNT_SELECTOR } from 'components/common/ShortcutHomeScreen/constants'

const el = document.querySelector(MOUNT_SELECTOR)

if (el && !window.IS_YDOC) {
  new Vue({
    el,
    vuetify,
    render: h => h(ShortcutHomeScreen),
  })
}
