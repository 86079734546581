<template>
  <VApp data-shortcut-home-screen>
    <SuggestMedtochkaBottomSheet
      v-model="isVisibleBottomSheet"
      @input="handleInputBottomSheet"
    />
  </VApp>
</template>

<script>
import UserStorage from 'modules/UserStorage'
import SuggestMedtochkaBottomSheet from 'components/common/ShortcutHomeScreen/components/SuggestMedtochkaBottomSheet.vue'
import { STORAGE_KEY } from 'components/common/ShortcutHomeScreen/constants'

export default {
  name: 'ShortcutHomeScreen',
  components: {
    SuggestMedtochkaBottomSheet,
  },
  data: () => ({
    isVisibleBottomSheet: false,
  }),
  mounted() {
    if (!UserStorage.get(STORAGE_KEY)) {
      this.isVisibleBottomSheet = true
    }
  },
  methods: {
    handleInputBottomSheet(isVisible) {
      if (!isVisible) {
        UserStorage.set(STORAGE_KEY, true)
      }
    },
  },
}
</script>

<style lang="scss">
@import '~www/themes/doctors/common/mixins';

#app[data-shortcut-home-screen] {
  @include v-app-reset;
}
</style>
