<template>
  <VBottomSheet
    :value="value"
    class="suggest-medtochka-bottom-sheet"
    transition="fade-transition"
    @input="$emit('input', $event)"
  >
    <VSheet
      class="px-4 pt-4 pb-6"
      color="bg-gray-0"
    >
      <div class="suggest-medtochka-bottom-sheet__header d-flex justify-end">
        <VCard
          class="suggest-medtochka-bottom-sheet__icon"
          width="72"
          height="72"
          elevation="1"
          rounded="lg"
        >
          <VImg
            src="/static/_v1/pd/icons/ui-kit/medtochka/icon.svg"
          />
        </VCard>
        <VIcon
          color="icon-secondary"
          size="24"
          @click="$emit('input', false)"
        >
          ui-icon-close-not-a-circle
        </VIcon>
      </div>
      <div class="ui-text ui-text_h6 mt-4">
        Приложение МедТочка — как&nbsp;ПроДокторов, но&nbsp;ещё&nbsp;лучше!
      </div>
      <div
        v-for="feature in FEATURE_LIST"
        :key="feature.icon"
        class="d-flex align-center ui-text ui-text_body-2 mt-4"
      >
        <VIcon
          color="icon-secondary"
          class="mr-4"
          size="24"
        >
          {{ feature.icon }}
        </VIcon>
        {{ feature.text }}
      </div>
      <VBtn
        class="mt-4"
        color="primary"
        depressed
        block
        @click="handleClickDownloadApp"
      >
        Скачать приложение
      </VBtn>
    </VSheet>
  </VBottomSheet>
</template>

<script setup lang="ts">
defineProps<{
  value: boolean
}>()

const FEATURE_LIST = [
  {
    icon: 'ui-icon-calendar-date-and-clock',
    text: 'История записей на приём с переносами и отменами',
  },
  {
    icon: 'ui-icon-med-book',
    text: 'Возможность поделиться медкартой с врачом',
  },
] as const

const emit = defineEmits(['input'])

function handleClickDownloadApp() {
  window.open('https://redirect.appmetrica.yandex.com/serve/677412678695663081', '_blank')
  emit('input', false)
}
</script>

<style lang="scss" scoped>
.suggest-medtochka-bottom-sheet {
  &__header {
    position: relative;
  }

  &__icon {
    position: absolute;
    left: 0;
    bottom: 0;
  }
}
</style>
