var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "VApp",
    { attrs: { "data-shortcut-home-screen": "" } },
    [
      _c("SuggestMedtochkaBottomSheet", {
        on: { input: _vm.handleInputBottomSheet },
        model: {
          value: _vm.isVisibleBottomSheet,
          callback: function ($$v) {
            _vm.isVisibleBottomSheet = $$v
          },
          expression: "isVisibleBottomSheet",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }